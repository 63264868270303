import { useRef, useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import {useTranslation} from 'react-i18next';


export const Contact = () => {

  const {t} = useTranslation();

  const form = useRef();

  const sendEmail = (e) => {

    emailjs.sendForm('service_rxxbaek', 'template_sxnz2h8', form.current, '6I7Cs1s3i-565Leny')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      
      handleSubmitForm();
  };


      const [fullName, setFullName] = useState('');
      const [email, setEmail] = useState('');
      const [message, setMessage] = useState('');

  
      const handleSubmitForm = event => {
       alert('form submitted');

        setFullName('');
        setEmail('');
        setMessage('');

      };

    

return (
  <div>
      <div id="contact">   
      <div className="container">
        <div className="col-md-8">
          <div className="row">
            <div className="section-title">
              <h2>{t('Be in touched with our Owner !')}               </h2>
               <h4 className="text-muted lead">Mr.Namatullah Mozammil</h4>
              <p>
              {t('Let us know your problems and ideas about the products and services !!')}
              </p>
            </div>
            <form name="sentMessage" className="sent-message" ref={form}  onSubmit={sendEmail} >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder={t('Enter your name')}
                      required
                      onChange={event => setFullName(event.target.value)}
                      value={fullName}
                      />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder={t('Enter your Email address')}
                      required
                      onChange={event => setEmail(event.target.value)}
                      value={email}
                      />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="4"
                  placeholder={t('Enter Message')}
                  required
                  onChange={event => setMessage(event.target.value)}
                  value={message}
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <div id="success"></div>
              <button type="submit" className="btn btn-custom btn-lg">
              {t('Send Message')}
              </button>
            </form>
          </div>
        </div>
        <div className="col-md-3 col-md-offset-1 contact-info">
          <div className="contact-item">
            <h1><li>{t('Contact Info')}</li></h1>
              <span>
                 <h4><li> {t('Addresses')} :</li></h4>
                <br/>
                <ul>
                  <li>
                      {t('Head office: Shar-e Naw, Ansari, Mohammadi Plaza;')}         
                  </li>
                  <br/>
                  <ol>
                      {t('Mandavi, Kabul: Karimi Brothers Market , shop No. (28)')}         
                  </ol>
                  <br/>
                   <ol>
                       {t('Mazar-e-Sharif: Kefait Market, Maimaneh Street, Omar Yusufzadeh Tea Shop. ')}        
                  </ol>
                </ul>  
                <i className="fa fa-phone"></i>  
               <h4><li> {t('To order, call the following numbers:')}</li></h4>
                    <br/>
                  <li>{t('Head office:')} {t('0202202784')}-{t('0744859696')}</li> 
                    <br/>
                  <li>{t('Mandavi kabul:')} {t('0797140090')}-{t('0778370459')} </li> 
                    <br/>
                  <li>{t('Mazar Sharif: ')} {t('0792609561')}</li>
                   <br/>      
                   <li><h4>{t('Email')}: </h4> altunbargsaffron1@gmail.com</li> 
              </span>
      
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="social">
              <ul>
                <li>
                  <a href="https://www.facebook.com/altunbargsaffron/">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                <a href="https://wa.me/020220784">
                    <i className="fa fa-whatsapp"></i>
                  </a>
                  </li>
                  <li>
                  <a href="https://mail.google.com/mail/altunbargsaffron1@gmail.com">
                    <i className="fa fa-envelope"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  </div>
    </div>
    <div id="footer">

   <div className="container text-center mb-100">
     <p>
      {t('© Copyright . All Rights Reserved')}
      </p>
     </div>
    </div>
 </div>
 
 
  ) 
}
