import img1 from "../images/Saffron1.jpeg";
import img2 from "../images/Saffron2.jpg";
import img3 from "../images/Saffron3.jpg";
import img4 from "../images/Saffron4.jpg";
import img5 from "../images/Saffron5.jpg";
import img6 from "../images/Saffron6.jpg";
import React from "react";
import {useTranslation} from 'react-i18next'

export const Products = () => {
  
  const {t} = useTranslation();


  return (
    <div id="products" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{t('PRODUCTS')}</h2>
          <p>
            {t('We love to show you some picture of our products')}
          </p>
          <p>
            {t('WholeSale')}
          </p>
        </div>
        <div className="row">
          <div className="products-items">
            <div className="col-sm-6 col-md-4 col-lg-4">
            <img src={img1} alt="" />
            <img src={img2} alt="" />
            </div>
          </div>
          <div className="products-items">
            <div className="col-sm-6 col-md-4 col-lg-4">
            <img src={img3} alt="" />
            <img src={img4} alt="" />
            </div>
          </div>
          <div className="products-items">
            <div className="col-sm-6 col-md-4 col-lg-4">
            <img src={img5} alt="" />
            <img src={img6} alt="" />
            </div>
          </div>
          </div>
        </div>
    </div>
  );
};
