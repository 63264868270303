import {useTranslation} from 'react-i18next';
import React, { Component }  from 'react';


const Languageoption = (props) => {

    const {t} = useTranslation();

    return(
<>

<select onChange={props.onChange}>
                
                <option>{t('Select Language:')}</option>
                <option value={'en'}>{t('English')}</option>
                <option value={'fr'}>{t('Farsi')}</option>
                <option value={'ps'}>{t('Pashto')}</option>
                <option value={'uz'}>{t('Uzbak')}</option>
            </select>



</>

    

     
    )
}
export default Languageoption;
